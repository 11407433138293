<template>
  <div id="preloader-active">
    <div class="preloader d-flex align-items-center justify-content-center">
      <div class="preloader-inner position-relative">
        <div class="preloader-circle"></div>

        <div class="preloader-img pere-text">
          <img src="../assets/img/logo/loder.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Preloader",

  mounted() {
    let timelapse = 1050;

    $("#preloader-active").delay(timelapse).fadeOut("slow");

    $("body").delay(timelapse).css({
      overflow: "visible",
    });
  },
};
</script>

<style scoped>
.preloader {
  background-color: #fecd00;
  z-index: 999999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-circle {
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 10;

  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-left-color: #fecd00;
  border-right-color: #fecd00;
  border-top-color: transparent;
  border-bottom-color: transparent;

  -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  background-color: #000000;

  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-img {
  display: inline-block;
  text-align: center;
  position: absolute;
  padding-top: 6px;
  margin: 0 auto;
  z-index: 200;
  top: 50%;
  left: 0;
  right: 0;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-img img {
  max-width: 65px;
}

.preloader .pere-text strong {
  text-transform: uppercase;
  font-weight: 800;
  color: #fecd00;
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);

    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);

    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}
</style>

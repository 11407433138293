import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import axios from "axios";
import VueAxios from "vue-axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueSimpleAlert from "vue-simple-alert";

// Effects for the page
// import VueParticlesBg from "particles-bg-vue"

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Axios library
Vue.use(VueAxios, axios);

const options = {
  width: 600,
  padding: "3em",
  color: "#fecd00",
  background: "rgba(0, 0, 0, 0.9)",
  confirmButtonColor: "#fecd00",
};

// SweetAlert library
Vue.use(VueSweetalert2, options);
// Alerts vue
Vue.use(VueSimpleAlert);

// Add library to project vue
// Vue.use(VueParticlesBg)

Vue.config.productionTip = false;

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
